<template>
  <div class="py-4 px-4 bg-white alert-marker">
    <div class="block w-full h-full">
      <div class="flex items-center">
        <div class="block w-1/2">
          <div class="flex justify-center items-center w-24">
            <img
              :src="getAvatar"
              class="w-20 h-20"
              style="object-fit: contain;"
            />
          </div>
          <div
            class="grid relative z-10 place-items-center py-px px-3 -mt-3 w-24 h-6 text-xs font-semibold uppercase bg-white rounded-full shadow-md text-oBlack"
          >
            {{ vehicle.bike_short_id }}
          </div>
        </div>
        <div class="flex justify-end items-center w-1/2">
          <qr-code
            :text="vehicle.lock.qr_code"
            :size="100"
            color="black"
            bg-color="white"
            error-level="L"
          >
          </qr-code>
        </div>
      </div>
      <div class="flex items-center mt-2 h-10 border-t-2 border-gray-200">
        <div class="flex items-center w-2/5">
          <span class="alert-title">Vehicle Status</span>
        </div>
        <div class="flex justify-end items-center w-3/5">
          <!-- <div class="justify-center items-center active_circle">
                        <div class="m_circle"></div>
                    </div> -->
          <ripple-status :status="vehicle.lock.is_operational" />
        </div>
      </div>

      <div :class="item_class">
        <div class="flex items-center w-2/5">
          <span class="alert-title">Battery</span>
        </div>
        <div class="flex justify-end items-center w-3/5">
          <battery-status
            :level="parseFloat(vehicle.lock.power_level)"
            class="mt-1 mr-0"
          />
          <!-- <span class="alert-locked">{{Number((vehicle.lock.power_level) / 100).toFixed(1)}}%</span> -->
        </div>
      </div>

      <div :class="item_class">
        <div class="flex items-center w-2/5">
          <span class="alert-title">Lock ID</span>
        </div>
        <div class="flex justify-end items-center w-3/5">
          <span class="alert-value">{{ vehicle.lock.lock_id }}</span>
        </div>
      </div>

      <div :class="item_class">
        <div class="flex items-center w-2/5">
          <span class="alert-title">Last Updated</span>
        </div>
        <div class="flex justify-end items-center w-3/5">
          <span class="alert-value">{{
            vehicle.last_connected_at.split('.')[0] | friendlyDateTime
          }}</span>
        </div>
      </div>

      <div :class="item_class" class="flex justify-between items-center">
        <div class="flex items-center w-full">
          <div class="flex items-center mt-px">
            <span class="alert-title">Lat:</span>
            <span class="ml-2 alert-value">{{ getLatLng('lat') }}</span>
          </div>
          <div class="flex items-center ml-2">
            <span class="alert-title">Long:</span>
            <span class="ml-2 alert-value">{{ getLatLng('lng') }}</span>
          </div>
        </div>
        <div class="w-6 text-right cursor-pointer" @click="copyToClipboard">
          <span class="alert-value"><i class="far fa-copy"></i></span>
        </div>
      </div>

      <div :class="item_class">
        <div class="flex items-center w-2/5">
          <span class="alert-title">Lock Status</span>
        </div>
        <div
          class="flex justify-end items-center w-3/5"
          @click="handleVehicleLockUnlock"
        >
          <div class="flex items-center">
            <div class="flex items-center">
              <t-toggle
                variant="lock"
                :disabled="true"
                v-model="vehicleLockStatus"
              />
            </div>

            <div
              class="flex items-center px-2 w-16 h-10 font-medium vld-parent"
            >
              <loading
                :height="25"
                color="#323740"
                v-if="lockReq"
                :active="true"
                loader="bars"
                :is-full-page="false"
              />
              <span v-if="!lockReq">{{ vehicle.trip_status }}</span>
            </div>
          </div>
          <!-- <t-toggle :checked="isLocked" disabled variant="success" />
          <span class="ml-1 alert-locked">{{ vehicle.trip_status }} </span> -->
        </div>
      </div>

      <div class="flex items-center mt-3">
        <div
          class="flex justify-center items-center w-3/4 detail-btn"
          @click="goToVehicleDetail"
        >
          <span>View Details</span>
        </div>
        <oto-map-button
          @click="goToGoogleMap"
          variant="teal"
          text=""
          v-tooltip.bottom="'Navigate'"
          style=" height: 38px;"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.alert-marker {
  position: absolute;
  z-index: 25;
  background: white;
  height: 450px;
  width: 300px;
  right: 10px;
  top: 10px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.alert-title {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.alert-value {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d75;
}
.alert-locked {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.active_circle {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background: #0ac84b2e;
  display: flex;
  margin-right: 8px;
}
.m_circle {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background: #0ac84b;
}
.detail-btn {
  /* margin-top: 10px; */
  width: 100%;
  height: 38px;
  background: #256baa;
  /* margin-left: 15px;
  */
  margin-right: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.detail-btn:hover {
  opacity: 0.8;
}
.detail-btn span {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
</style>

<script>
import { VehicleConfig } from '@/config/VehicleConfig'

import RippleStatus from '@/components/badge/RippleStatus.vue'

import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import OtoMapButton from '@/components/ui/OtoMapButton'
import CopyToClipboardMixin from '@/mixins/copyToClipboardMixin'

export default {
  name: 'RightAlert',
  mixins: [CopyToClipboardMixin],
  components: {
    BatteryStatus,
    RippleStatus,
    OtoMapButton,
  },
  props: {
    avatar: {
      required: false,
    },
    vehicle: {
      required: false,
    },
    item_class: {
      required: false,
    },
  },
  mounted() {
    console.log(this.vehicle.lock.is_locked)
  },
  data() {
    return {
      lockReq: false,
    }
  },
  computed: {
    getAvatar: function() {
      return this.avatar ?? require('@/assets/img/avatar-male.png')
    },
    isLocked() {
      return this.vehicle.trip_status === 'Locked' ? true : false
    },
    id() {
      return this.vehicle.id
    },
    vehicleLockStatus: {
      get: function() {
        return this.vehicle.lock.is_locked
      },
      set: function(val) {
        this.vehicle.lock.is_locked = val
      },
    },
  },
  methods: {
    goToVehicleDetail() {
      // console.log("detail = ", this.vehicle);
      this.$router.push(`/vehicle-management/vehicles/${this.vehicle.id}`)
    },
    goToGoogleMap() {
      window.open(this.getLatLngUrl())
    },
    getLatLng(type) {
      let [lat, lng] = this.vehicle.location.split(',')
      if (type === 'lat') {
        return lat
      }
      return lng
    },
    getLatLngUrl() {
      return `https://www.google.com/maps/search/?api=1&query=${this.getLatLng(
        'lat'
      )},${this.getLatLng('lng')}`
    },
    copyToClipboard() {
      // using mixin
      this.copyContentToClipboard(this.getLatLngUrl(), {
        title: 'Lat Long Copied',
        text: 'Lat & Long has been copied to your clipboard',
      })
    },
    async handleVehicleLockUnlock() {
      if (this.vehicle.lock.is_operational === false) {
        alert('The vehicle is not active yet!')
        return
      }
      // prevent if already req is made
      if (this.lockReq === true) {
        return
      }

      this.lockReq = true

      console.log(this.vehicleLockStatus)

      let url =
        this.vehicleLockStatus === true
          ? VehicleConfig.api.status.lock(this.id)
          : VehicleConfig.api.status.unlock(this.id)

      let attempt
      try {
        attempt = this.$http.post(url)
      } catch (error) {
        console.log(error)
      }

      if (attempt) {
        this.getVehicleLockStatus()
      }
    },
    async getVehicleLockStatus(delay = 5000) {
      setTimeout(async () => {
        console.log('wait...')

        await this.$http
          .get(VehicleConfig.api.status.current(this.id))
          .then((res) => {
            console.log(res.data)
            // update only if the lock status has changed
            if (res.data.locked !== this.vehicleLockStatus) {
              this.vehicleLockStatus = res.data.locked

              let mTitle = res.data.locked ? 'Locked' : 'Unlocked'
              this.$notify(
                {
                  group: 'bottomRight',
                  type: 'success',
                  title: mTitle,
                  text: `The vehicle is now ${mTitle.toLowerCase()}`,
                },
                5000
              )
            } else {
              // action is the oposite of the current status
              let msg =
                this.vehicleLockStatus === true
                  ? 'Failed to unlock!'
                  : 'Failed to lock!'
              alert(`${msg} Please try again`)
            }
          })
          .catch(() => {
            let msg =
              this.vehicleLockStatus === true
                ? 'E: Failed to unlock!'
                : 'E: Failed to lock!'
            alert(`${msg} Please try again`)
          })
          .finally(() => {
            this.lockReq = false
          })
      }, delay)
    },
  },
}
</script>
