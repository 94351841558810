<template>
  <div
    class="relative w-3/4 h-auto bg-white shadow-sm xl-max:w-full xl-max:h-96"
  >
    <right-alert
      :avatar="getScooterImage"
      :vehicle="alertVehicle"
      :item_class="
        `flex h-10 mt-1 mb-1 border-t-2 border-gray-200 items-center`
      "
      v-if="infoAlertOpen"
    />
    <gmap-map
      ref="map"
      :zoom="zoom"
      :center="center"
      map-type-id="terrain"
      style="width: 100%; height: 100%;"
      @click="toggleMap"
    >
      <gmap-marker
        :key="index"
        v-for="(vehicle, index) in vehicles"
        :position="getLatLng(vehicle.location)"
        :icon="getMarkerIconSrcForVehiclePower(vehicle)"
        @mouseover="toggleMarker(vehicle, index)"
        @click="toggleAlert(vehicle)"
      >
      </gmap-marker>
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { gmapApi } from 'vue2-google-maps'
//import { VehicleConfig } from "@/config/VehicleConfig";
import RightAlert from './RightAlert'

import vehicleFlagHelperMixin from '@/mixins/vehicleFlagHelperMixin'

export default {
  name: 'LiveGoogleMap',
  mixins: [vehicleFlagHelperMixin],
  components: {
    RightAlert,
  },
  props: {
    service_area: {
      required: true,
    },
    parking_area: {
      required: true,
    },
    restricted_area: {
      required: true,
    },
    slow_zone_area: {
      required: true,
    },
  },

  data() {
    return {
      //marker window
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      alertVehicle: null,
      infoWinOpen: false,
      infoAlertOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -70,
        },
      },
      vehicle_info: {},
      currentMidx: null,
      //
      center: {
        lat: 0.975795745849613,
        lng: 10.0025258622632,
      },
      zoom: 2,
      map: null,
      drawingOptions: {
        fillColor: 'rgba(74, 255, 92, 0.13)',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      p_drawOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#00acff',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      r_drawOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      s_drawOptions: {
        fillColor: 'rgba(255, 186, 64, 0.13)', //
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#ff7c10',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      show_map: false,
    }
  },
  computed: {
    google: gmapApi,
    ...mapState({
      vehicles: (state) => state.livemap.selected_vehicles,
    }),
    getScooterImage() {
      return require('@/assets/img/scooter.png')
    },
  },
  // async created () {

  // },
  async created() {
    //this.getCurrentLocation()
    console.log('Plotting Data on the map')
    setTimeout(() => {
      if (!this.$refs.map) return
      var self = this
      this.show_map = true
      this.$refs.map.$mapPromise.then((map) => {
        self.map = map
        self.service_area.forEach((item) => {
          const overlay = self.parseToOverlay(item.coords)
          self.setOverlayOnMap(overlay, 'service')
          self.setMapCenterToDrawingCenter(overlay, false)
        })
        self.parking_area.forEach((item) => {
          const overlay = self.parseToOverlay(item.coords)
          self.setOverlayOnMap(overlay, 'parking')
        })
        self.restricted_area.forEach((item) => {
          const overlay = self.parseToOverlay(item.coords)
          self.setOverlayOnMap(overlay, 'restricted')
        })
        self.slow_zone_area.forEach((item) => {
          const overlay = self.parseToOverlay(item.coords)
          self.setOverlayOnMap(overlay, 'slow')
        })
      })
    }, 1000)
  },
  methods: {
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = Number(position.split(',')[0])
      let lng = Number(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },

    toggleMap() {
      this.infoWinOpen = false
      this.infoAlertOpen = false
    },
    toggleAlert(vehicle) {
      this.infoWinOpen = false
      this.infoAlertOpen = true
      this.alertVehicle = vehicle

      if (vehicle.location != null) {
        this.center = this.getLatLng(vehicle.location)
        this.zoom = 15
      }

      // this.$http.get(VehicleConfig.api.single(vehicle.id)).then(res => {
      //   console.log('res = ' , res.data)
      //   // this.infoAlertOpen = true;
      //   // this.alertVehicle = res.data
      // }).catch(err => {
      //   console.log('err = ' , err)
      //   this.infoAlertOpen = false;
      // })
    },
    toggleMarker(vehicle, idx) {
      this.infoWindowPos = this.getLatLng(vehicle.location)
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
      this.infoContent = this.getInfoWindowContent(vehicle)
    },

    showAlert(item) {
      console.log('alert')
      if (item.location != null) {
        this.center = this.getLatLng(item.location)
        this.zoom = 15
      } else {
        this.zoom = 2
      }
      this.infoWinOpen = false
      this.infoAlertOpen = true
      this.alertVehicle = item
    },

    getInfoWindowContent: function(item) {
      return `
        <div class="card">
          <div class="card-content">
            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Vehicle Name:</span>
              <span class="ml-2 card-text">${
                item.name == undefined ? '' : this.truncateStr(item.name)
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">IMIE:</span>
              <span class="ml-2 card-text">${item.lock.lock_id}</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">QR:</span>
              <span class="ml-2 card-text">${item.lock.qr_code}</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Battery:</span>
              <span class="ml-2 card-text">${item.lock.power_level}</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Latitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.location).lat
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Longtitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.location).lng
              }</span>
            </div>
          </div>
        </div>
      `
    },
    truncateStr(str) {
      if (str.length < 17) return str
      return str.slice(0, 15) + '..'
    },

    //
    setOverlayOnMap(payload, type) {
      var options = {}
      switch (type) {
        case 'service':
          options = this.drawingOptions
          break
        case 'parking':
          options = this.p_drawOptions
          break
        case 'restricted':
          options = this.r_drawOptions
          break
        case 'slow':
          options = this.s_drawOptions
          break
      }

      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...options,
      })
      this.parsedPolygon.setMap(this.map)
    },

    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },

    setMapCenterToDrawingCenter(paths, panToCenter = true) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      if (panToCenter) {
        this.map.panTo(newCenter)
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }
      //reset map zoom based on new poly (can't see whole poly on render if the poly is bigger then the current set zoom)
      this.map.fitBounds(bounds)
    },

    getCurrentLocation() {
      if (!('geolocation' in navigator)) {
        this.errorStr = 'Geolocation is not available.'
        return
      }
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('position = ', position)
        // this.center.lat = position.coords.latitude;
        // this.center.lng = position.coords.longitude;
      })
    },
  },
}
</script>

<style>
.custom-height {
  height: 500px;
}
.gm-style-iw {
  padding: 12px !important;
  width: 265px;
  height: 185px;
}
.card {
  width: 200px;
  height: 200px;
}
.card-title {
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.card-text {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
