<template>
  <div class="w-full h-full mt-1 bg-white rounded-md shadow-md">
    <setting-list v-on:refreshData="refreshData" />
    <section
      class="flex flex-wrap mt-1 mb-1"
      style="min-height: calc(100vh - 190px);"
    >
      <sidebar
        v-on:changeStatus="onChangeStatus"
        v-on:changeSearch="onChangeSearch"
      />

      <!-- <template v-if="isLoading">
        <div class="w-full h-full bg-red-500 vld-parent">
          <loading :active.sync="isLoading" :is-full-page="false" />
        </div>
      </template> -->

      <live-google-map
        :service_area="service_area"
        :parking_area="parking_area"
        :restricted_area="restricted_area"
        :slow_zone_area="slow_zone_area"
        v-if="!isLoading"
        ref="live_gmap"
      />
    </section>
  </div>
</template>

<script>
//import BaseLayout from "@/views/shared/BaseLayout.vue";
import SettingList from '@/components/live-map/SettingList'
import Sidebar from '@/components/live-map/Sidebar'
import LiveGoogleMap from '@/components/live-map/LiveGoogleMap'
import { ServiceAreaConfig } from '@/config/ServiceAreaConfig'
import { ParkingAreaConfig } from '@/config/ParkingAreaConfig'
import { RestrictedAreaConfig } from '@/config/RestrictedAreaConfig'
import { SlowZoneAreaConfig } from '@/config/SlowZoneAreaConfig'
import { VehicleConfig } from '@/config/VehicleConfig'
import { mapState } from 'vuex'

export default {
  name: 'ChildMap',
  components: {
    SettingList,
    Sidebar,
    LiveGoogleMap,
  },
  data() {
    return {
      service_area: [],
      parking_area: [],
      restricted_area: [],
      slow_zone_area: [],
      vehicle_data: {},
      vehicle_summary: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      orgin_vehicles: (state) => state.livemap.vehicles,
      vehicles: (state) => state.livemap.selected_vehicles,
      filter_fleet: (state) => state.livemap.filter_fleet,
      filter_battery: (state) => state.livemap.filter_battery,
      filter_gps: (state) => state.livemap.filter_gps,
      search_text: (state) => state.livemap.search_text,
    }),
  },
  async mounted() {
    this.fetchData()
  },
  beforeDestroy() {
    this.$store.dispatch('livemap/removeSockListener')
  },
  methods: {
    async refreshData() {
      let result = '?'
      if (this.filter_fleet == null || this.filter_fleet == '') result += ''
      else result += this.filter_fleet + '&'

      if (this.filter_battery == null || this.filter_battery == '') result += ''
      else result += this.filter_battery + '&'

      if (this.filter_gps == null || this.filter_gps == '') result += ''
      else result += this.filter_gps + '&'

      if (this.search_text == null || this.search_text == '') {
        result += ''
      } else {
        result += this.search_text + '&'
      }
      if (result == '?') result = ''
      this.isLoading = true
      var vehicles_arr = await this.$http
        .get(
          VehicleConfig.api.index + result + (result !== '' ? 'livemap' : '')
        )
        .then((res) => res.data)
        .catch((err) => console.log('err = ', err))
      this.$store.dispatch('livemap/saveSelectedVehicleData', {
        data: vehicles_arr.data,
      })
      this.$store.dispatch('livemap/saveSummery', {
        data: vehicles_arr.meta.summary,
      })

      let fleetId = null
      if (this.filter_fleet) {
        fleetId = this.filter_fleet.replace('fleet=', '')
      }

      this.getCoordinates({
        fleet: fleetId,
      })

      this.isLoading = false
      // let endp = VehicleConfig.api.index + result;
      // this.fetchData(endp);
    },
    onChangeSearch(item) {
      this.$refs.live_gmap.showAlert(item)
    },
    onChangeStatus(ride_status, vehicle_status, operational_status) {
      // console.warn(ride_status, vehicle_status, operational_status);

      var result = []
      var count = 0
      ride_status.forEach((element) => {
        if (element.status) {
          this.orgin_vehicles.forEach((item) => {
            if (item.status_flags[element.id]) {
              result.push(item)
            }
          })
        } else {
          count++
        }
      })

      vehicle_status.forEach((element) => {
        if (element.status) {
          this.orgin_vehicles.forEach((item) => {
            if (item.general_flags[element.id]) {
              result.push(item)
            }
          })
        } else {
          count++
        }
      })

      operational_status.forEach((element) => {
        if (element.status) {
          this.orgin_vehicles.forEach((item) => {
            if (item.operational_flags[element.id]) {
              result.push(item)
            }
          })
        } else {
          count++
        }
      })

      if (count == 11) {
        this.$store.dispatch('livemap/saveSelectedVehicleData', {
          data: this.orgin_vehicles,
        })
      } else {
        this.$store.dispatch('livemap/saveSelectedVehicleData', {
          data: result,
        })
      }
    },
    async fetchData(endpoint = VehicleConfig.api.index + '?livemap') {
      this.isLoading = true
      this.$store.dispatch('livemap/forgetAllData')
      this.$store.dispatch('livemap/removeSockListener')

      var vehicle_data = await this.$http
        .get(endpoint)
        .then((res) => res.data)
        .catch((err) => console.log('err = ', err))

      var vehicles_arr = []
      if (vehicle_data.data == undefined) {
        vehicles_arr = []
        this.status_arr = {}
      } else {
        vehicles_arr = vehicle_data.data
        if (vehicle_data.meta && vehicle_data.meta.summary)
          this.status_arr = vehicle_data.meta.summary
        else this.status_arr = {}
      }
      this.$store.dispatch('livemap/saveSummery', {
        data: this.status_arr,
      })

      this.$store.dispatch('livemap/saveVehicleData', {
        data: vehicles_arr,
      })

      this.$store.dispatch('livemap/saveSelectedVehicleData', {
        data: vehicles_arr,
      })
      this.$store.dispatch('livemap/openSockListener')

      this.getCoordinates()

      this.isLoading = false
    },
    async getCoordinates(
      filters = {
        fleet: null,
      },
      options = {
        serviceArea: {
          fetch: true,
          store: true,
        },
        parkingArea: {
          fetch: true,
          store: true,
        },
        slowZoneArea: {
          fetch: true,
          store: true,
        },
        restrictedArea: {
          fetch: true,
          store: true,
        },
      },
      endpoints = {
        serviceArea: ServiceAreaConfig.api.index,
        parkingArea: ParkingAreaConfig.api.index,
        slowZoneArea: SlowZoneAreaConfig.api.index,
        restrictedArea: RestrictedAreaConfig.api.index,
      }
    ) {
      let getEndpointFor = (name = 'serviceArea') => {
        // it's writter like this to support more filters in future
        let queryParam = '?'
        if (filters.fleet) {
          queryParam += `fleet=${filters.fleet}`
        }

        // removing unnecessay queryParam
        queryParam = queryParam.length === 1 ? '' : queryParam

        return endpoints[name] + queryParam
      }
      // let requests = [];
      console.log(filters)

      if (options.serviceArea.fetch) {
        await this.$http
          .get(getEndpointFor('serviceArea'))
          .then((res) => {
            this.service_area = res.data.data

            if (options.serviceArea.store) {
              this.$store.dispatch('livemap/saveServicesData', {
                data: this.service_area,
              })
            }
          })
          .catch((err) => console.log(err))
      }

      // console.log("SECreat:", this.service_area);

      if (options.parkingArea.fetch) {
        await this.$http
          .get(getEndpointFor('parkingArea'))
          .then((res) => {
            this.parking_area = res.data.data

            if (options.parkingArea.store) {
              this.$store.dispatch('livemap/saveParkingData', {
                data: this.parking_area,
              })
            }
          })
          .catch((err) => console.log(err))
      }

      if (options.restrictedArea.fetch) {
        await this.$http
          .get(getEndpointFor('restrictedArea'))
          .then((res) => {
            this.restricted_area = res.data.data

            if (options.restrictedArea.store) {
              this.$store.dispatch('livemap/saveRestrictedData', {
                data: this.restricted_area,
              })
            }
          })
          .catch((err) => console.log(err))
      }

      if (options.slowZoneArea.fetch) {
        await this.$http
          .get(getEndpointFor('slowZoneArea'))
          .then((res) => {
            this.slow_zone_area = res.data.data

            if (options.slowZoneArea.store) {
              this.$store.dispatch('livemap/saveSlowZoneData', {
                data: this.slow_zone_area,
              })
            }
          })
          .catch((err) => console.log(err))
      }
    },
    async getMM(endpoint = VehicleConfig.api.index) {
      await this.$http
        .get(endpoint)
        .then((res) => {
          this.vehicles_data = res.data.data
          // this.vehicles_summary = res.data.data.meta;

          if (this.vehicles_data.meta && this.vehicles_data.meta.summary)
            this.vehicles_summary = this.vehicles_data.meta.summary

          this.$store.dispatch('livemap/saveSummery', {
            data: this.vehicles_summary,
          })

          this.$store.dispatch('livemap/saveVehicleData', {
            data: this.vehicles_data,
          })

          this.$store.dispatch('livemap/saveSelectedVehicleData', {
            data: this.vehicles_data,
          })
        })
        .catch((err) => console.log('err = ', err))

      console.table('vehicles = ', this.vehicles_data)
    },
  },
}
</script>

<style></style>
