export const ReportConfig = {
  api: {
    // get
    index: '/dashboard/reports/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/reports/${id}/`
    },
    // post
    create: '/dashboard/reports/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/reports/${id}/`
    },
  },
  events: {
    name: 'reports',
    // refresh-index-data
    refresh: `rid-report`,
    // slideover-right
    sorId: 'report',
    sorOpen: 'sor-open-report',
    sorClose: 'sor-close-report',
    sorToggle: 'sor-toggle-report',
    // editing-data
    editingData: 'edit-report-data',
    // viewing-data
    viewingData: 'view-report-data',
  },
}
