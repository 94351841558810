<template>
  <div class="flex items-center">
    <div class="flex relative w-5 h-5">
      <span
        v-if="status"
        class="inline-flex absolute w-full h-full bg-green-500 rounded-full opacity-75 animate-ping"
      />
      <span
        class="inline-flex relative mt-1 ml-1 w-3 h-3 rounded-full"
        :class="getClass"
      />
    </div>

    <div :class="textClass">
      {{ getText }}

      <span v-if="isAvailable" class="ml-1 text-xs text-oGreen">
        <i class="fas fa-check-circle" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RippleStatus',
  props: {
    status: { required: false, type: Boolean, default: false },
    textClass: {
      required: false,
      type: String,
      default: 'ml-2 font-medium text-oDark text-14px',
    },
    textActive: { required: false, type: String, default: 'Active' },
    textInactive: { required: false, type: String, default: 'Inactive' },

    isAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getText() {
      return this.status ? this.textActive : this.textInactive
    },
    getClass() {
      return this.status ? 'bg-oGreen' : 'bg-oGray'
    },
  },
}
</script>

<style></style>
