<template>
  <div
    class="w-full h-auto xl-max:h-auto xl-max:px-2 xl-max:py-2 shadow-sm items-center flex px-2 flex-wrap"
  >
    <div class="w-3/5 md-max:w-auto md-max:flex-wrap flex">
      <!-- <setting-item 
        :items="countries"
        title="Country"
        type="country"
        v-on:sort="onSort"
      /> -->
      <setting-item
        :items="fleets"
        title="All Fleets"
        type="fleet"
        v-on:sort="onSort"
      />
      <setting-item
        :items="batteries"
        title="All Battery"
        type="battery"
        v-on:sort="onSort"
      />
      <setting-item
        :items="locations"
        title="All GPS"
        type="gps"
        v-on:sort="onSort"
      />
    </div>
    <div class="w-2/5 flex justify-end md-max:w-auto">
      <div class="setting-body mr-2 mb-2 mt-2">
        <div class="flex h-full">
          <div class="grid items-center ml-3">
            <span class="pro-setting-title">Realtime Map</span>
          </div>
          <div class="flex items-center custom-right">
            <t-toggle :checked="isRealtime" @click="toggleRealtime()" />
          </div>
        </div>
      </div>
      <!-- <setting-item
        :items="batteries"
        title="Today"
        type="date"
        v-on:sort="onSort"
      /> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
//import BaseLayout from "@/views/shared/BaseLayout.vue";
//import PageSection from "@/components/layout/PageSection";
//import { ServiceAreaConfig } from "@/config/ServiceAreaConfig";
import { ReportConfig } from '@/config/ReportConfig'
//import PageSection from '../../components/layout/PageSection.vue';
import { DropdownConfig } from '@/config/DropdownConfig'
import { FleetConfig } from '@/config/FleetConfig'
import SettingItem from './SettingItem'

export default {
  name: 'SettingList',
  components: {
    SettingItem,
  },
  data() {
    return {
      countries: [],
      fleets: [],
      country: '',
      batteries: [
        {
          id: -1,
          name: 'All Battery',
        },
        {
          id: 0,
          name: 'Empty Battery',
        },
        {
          id: 1,
          name: 'Critical (0 ~ 1%)',
        },
        {
          id: 2,
          name: 'Low (2 ~ 24%)',
        },
        {
          id: 3,
          name: 'Medium (25 ~ 50%)',
        },
        {
          id: 4,
          name: 'Normal (51 ~ 75%)',
        },
        {
          id: 5,
          name: 'Full (76 ~ 100%)',
        },
      ],
      locations: [
        {
          id: -1,
          name: 'All GPS',
        },
        {
          id: 0,
          name: '0 ~ 2 Hours',
        },
        {
          id: 0,
          name: '2 ~ 5 Hours',
        },
        {
          id: 0,
          name: '5 ~ 10 Hours',
        },
        {
          id: 0,
          name: '10 ~ 24 Hours',
        },
        {
          id: 0,
          name: '24 Hours Higher',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      service_area: (state) => state.livemap.service_area,
      vehicles: (state) => state.livemap.vehicles,
      isRealtime: (state) => state.livemap.isRealtime,
    }),
  },
  async created() {
    console.log('vehicles = ', this.vehicles)
    this.countries = await this.$http
      .get(DropdownConfig.api.country)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    var arr = await this.$http
      .get(FleetConfig.api.index)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))

    var result = []
    result.push({
      id: -1,
      name: 'All Fleets',
    })
    arr.forEach((item) => {
      result.push(item)
    })
    this.fleets = result
  },
  async mounted() {
    window.addEventListener(ReportConfig.events.refresh, () => {
      //this.getIndexData(this.getQueryString);
    })
  },
  methods: {
    toggleRealtime() {
      this.$store.dispatch('livemap/toggleRealtimeMode')
      console.warn(`Realtime mode ${this.isRealtime ? 'ON' : 'OFF'}`)
      this.$notify(
        {
          group: 'generic',
          type: `${this.isRealtime ? 'success' : 'warn'}`,
          title: `Realtime ${this.isRealtime ? 'ON' : 'OFF'}`,
          text: `Realtime updates are turned ${this.isRealtime ? 'on' : 'off'}`,
        },
        2000
      )
    },
    onSort(item, type) {
      var id = item.id
      // let upper = "?";
      let params = ''
      if (type == 'fleet') {
        if (id == -1) {
          params += ''
        } else {
          params += 'fleet=' + item.id
        }
        console.log('fleet', item, id, params)
        this.$store.dispatch('livemap/saveFilterFleet', {
          data: params,
        })
      }

      if (type == 'battery') {
        if (id == 0) {
          params += 'min_power_level=0&max_power_level=0'
        } else if (id == 1) {
          params += 'min_power_level=0&max_power_level=2'
        } else if (id == 2) {
          params += 'min_power_level=2&max_power_level=24'
        } else if (id == 3) {
          params += 'min_power_level=24&max_power_level=50'
        } else if (id == 4) {
          params += 'min_power_level=50&max_power_level=75'
        } else if (id == 5) {
          params += 'min_power_level=75&max_power_level=100'
        } else if (id == -1) {
          params += ''
        }
        this.$store.dispatch('livemap/saveFilterBattery', {
          data: params,
        })
      }

      if (type == 'gps') {
        if (id == -1) {
          params += ''
        } else if (id == 0) {
          params += 'min_last_heartbeat_time=0&max_last_heartbeat_time=2'
        } else if (id == 1) {
          params += 'min_last_heartbeat_time=2&max_last_heartbeat_time=5'
        } else if (id == 2) {
          params += 'min_last_heartbeat_time=5&max_last_heartbeat_time=10'
        } else if (id == 3) {
          params += 'min_last_heartbeat_time=10&max_last_heartbeat_time=24'
        } else if (id == 4) {
          params += 'min_last_heartbeat_time=24'
        }
        this.$store.dispatch('livemap/saveFilterGps', {
          data: params,
        })
      }
      this.$emit('refreshData')
    },
  },
}
</script>

<style></style>
