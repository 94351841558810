<template>
  <base-layout>
    <!--<div class="w-full shadow-sm bg-white flex" style="height:65px;">
      <div class="sm-max:w-0 w-1/3"></div>
      <div class="sm-max:w-full w-1/3 flex items-end">
        <anchor-tabs :tabs="tabs"></anchor-tabs>
      </div>
      <div class="sm-max:w-0 w-1/3"></div>
    </div>-->
    <child-map></child-map>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import { ReportConfig } from '@/config/ReportConfig'
//import AnchorTabs from "@/components/tabs/AnchorTabs";
import ChildMap from './ChildMap.vue'

export default {
  name: 'LiveMap',
  components: {
    BaseLayout,
    //AnchorTabs,
    ChildMap,
    //PageSection
  },
  data() {
    return {
      tabs: [
        {
          text: 'Map',
          href: this.getTabHref(`maps`),
        },
        {
          text: 'Timeline',
          href: this.getTabHref(`timeline`),
        },
        {
          text: 'HeatMap',
          href: this.getTabHref(`heat-map`),
        },
      ],
    }
  },
  async mounted() {
    //this.$router.push(this.tabs[0].href); //made by guang
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(ReportConfig.events.refresh, () => {
      //this.getIndexData(this.getQueryString);
    })
  },
  methods: {
    // getReportStatusChoice: function(status) {
    //   // console.log(type)
    //   return status in this.reportStatusChoice
    //     ? this.reportStatusChoice[status]
    //     : "--";
    // },
    // getReportCategoryChoice: function(cat) {
    //   // console.log(type)
    //   return cat in this.reportCategoryChoice
    //     ? this.reportCategoryChoice[cat]
    //     : "--";
    // },
    // edit: function(item) {
    //   console.log(item);
    //   // EventBus.$emit(SubscriptionConfig.events.editingData, item);
    //   // dispatchEvent(new Event(SubscriptionConfig.events.sorToggle));
    // },
    // view: function(item) {
    //   console.log(item);
    //   // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
    //   // this.$modal.show(SubscriptionConfig.events.viewingData);
    // }
    getTabHref(path) {
      return `/live-map/${path}`
    },
  },
}
</script>

<style></style>
