<template>
  <div
    v-if="show_menu"
    :class="type == 'search' ? 'menu mt-2 width-300' : 'menu width-180 mt-2'"
  >
    <div
      class="menu-item flex h-8 px-4 items-center"
      v-for="(item, index) in items"
      :key="index"
      @click="itemClicked(item)"
    >
      <span>
        {{ type == 'search' ? item.lock.lock_id : item.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      showMenu: false,
    }
  },
  props: {
    items: {
      required: false,
    },
    show_menu: {
      required: false,
    },
    type: {
      required: false,
    },
  },
  created() {},
  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu
    },
    itemClicked(item) {
      this.toggleShow()
      this.$emit('selItem', item)
    },
    init() {
      //alert(this.items)
    },
  },
  mounted: function() {
    this.init()
  },
}
</script>

<style scoped>
.width-180 {
  width: 180px;
  z-index: 11;
  box-shadow: 1px 1px 10px 1px #d2d0d04f;
}
.width-300 {
  width: 300px;
  z-index: 11;
  box-shadow: 1px 1px 10px 1px #d2d0d04f;
}
.menu-item:hover {
  background: #f3f3f3;
}
</style>
