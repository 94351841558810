<template>
  <div
    class="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer"
  >
    <svg
      class="w-5 h-3 focus:outline-none"
      fill="none"
      :stroke="color ? 'white' : 'currentColor'"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      required: false,
    },
  },
}
</script>
<style scoped>
.oto-plus-bg {
  background-color: #2978ff;
}
</style>
