<template>
  <div
    class="z-10 w-1/4 h-auto px-4 py-4 bg-white shadow-md xl-max:w-full left-shadow"
  >
    <oto-search
      type="livemap"
      v-on:input="changeSearch"
      v-on:dismiss="onDismiss"
    />
    <oto-map-drow-down-menu
      :items="items"
      :show_menu="show_search"
      v-on:selItem="SelectedItem"
      type="search"
    ></oto-map-drow-down-menu>
    <div class="all-vehicle">
      <div class="flex items-center w-1/3">
        <span class="m-auto ml-3">All Vehicles</span>
      </div>
      <div class="flex items-center justify-end w-2/3 pr-3">
        <span class="all-vehicle-title">{{
          summery ? summery.total : '0'
        }}</span>
      </div>
    </div>
    <div v-if="ride_status != null">
      <status-body
        :status="ride_status.data"
        :title="ride_status.title"
        v-on:selectedStatus="changeStatus"
        type="ride"
        ref="ride"
      />
      <status-body
        :status="vehicle_status.data"
        :title="vehicle_status.title"
        v-on:selectedStatus="changeStatus"
        type="vehicle"
        ref="vehicle"
      />
      <!-- <status-body
        :status="operational_status.data"
        :title="operational_status.title"
        v-on:selectedStatus="changeStatus"
        type="operational"
        ref="operational"
      /> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OtoSearch from '@/components/ui/OtoSearch.vue'
import StatusBody from '@/components/live-map/StatusBody'
import OtoMapDrowDownMenu from '@/components/ui/OtoMapDrowDownMenu.vue'

export default {
  name: 'Sidebar',
  components: {
    OtoSearch,
    StatusBody,
    OtoMapDrowDownMenu,
  },
  data() {
    return {
      ride_status: null,
      vehicle_status: null,
      operational_status: null,
      init_value: false,
      search: '',
      items: [],
      show_search: false,
    }
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.livemap.vehicles,
      summery: (state) => state.livemap.summery,
    }),
  },
  watch: {
    summery: {
      deep: true,
      handler: function() {
        this.initSidebar()
      },
    },
  },
  async mounted() {
    this.initSidebar()
    console.warn('SidebarLogOnMounted=>', this.$store.state.livemap.summery)
  },
  methods: {
    initSidebar() {
      setTimeout(() => {
        if (this.summery == null || this.summery == undefined) return
        this.ride_status = {
          title: 'Ride Status',
          data: [
            {
              id: 'is_reserved',
              name: 'On Reservation',
              count: this.summery.on_reservation,
              icon: 'is_reserved_icon.png',
              status: false,
            },
            {
              id: 'is_parking',
              name: 'Parking',
              count: this.summery.parking,
              icon: 'is_parking_icon.png',
              status: false,
            },
            {
              id: 'is_illigaly_parking',
              name: 'Illigaly Parked',
              count: this.summery.illegally_parking,
              icon: 'illigally-parked.svg',
              status: false,
            },
            {
              id: 'is_on_ride',
              name: 'On Ride',
              count: this.summery.on_ride,
              icon: 'is_on_ride_icon.png',
              status: false,
            },
            {
              id: 'is_charging',
              name: 'On charging',
              count: this.summery.on_charging,
              icon: 'is_charging_icon.png',
              status: false,
            },
          ],
        }
        this.vehicle_status = {
          title: 'Vehicle Flag',
          data: [
            {
              id: 'missing',
              name: 'Missing',
              count: this.summery.missing,
              icon: 'missing_icon.png',
              status: false,
            },
            {
              id: 'iot_fault',
              name: 'IOT Fault',
              count: this.summery.iot_fault,
              icon: 'iot_fault_icon.png',
              status: false,
            },
            {
              id: 'low_battery',
              name: 'Low battery',
              count: this.summery.low_battery,
              icon: 'low_battery_icon.png',
              status: false,
            },
            {
              id: 'geo_fence_alert',
              name: 'Geo fence alart',
              count: this.summery.geofence_alert,
              icon: 'geo_fence_alert_icon.png',
              status: false,
            },
          ],
        }
        this.operational_status = {
          title: 'Operational Flag',
          data: [
            {
              id: 'rebalance',
              name: 'Rebalance',
              count: this.summery.rebalance,
              icon: 'rebalance_icon.png',
              status: false,
            },
            {
              id: 'maintainance',
              name: 'Maintainance',
              count: this.summery.maintainance,
              icon: 'maintainance_icon.png',
              status: false,
            },
            {
              id: 'charging_pick',
              name: 'Charging Pick',
              count: this.summery.charging_pick,
              icon: 'charging_pick_icon.png',
              status: false,
            },
          ],
        }
      }, 500)
    },
    changeStatus(data, type, index) {
      if (type == 'ride') {
        if (data == null) {
          //unselected
          this.ride_status.data[index].status = false
        } else {
          this.ride_status.data[index].status = true
        }
      } else if (type == 'vehicle') {
        if (data == null) {
          //unselected
          this.vehicle_status.data[index].status = false
        } else {
          this.vehicle_status.data[index].status = true
        }
      } else if (type == 'operational') {
        if (data == null) {
          //unselected
          this.operational_status.data[index].status = false
        } else {
          this.operational_status.data[index].status = true
        }
      }
      // if (data != null) {
      //     if (type == 'ride') {
      //         this.$refs.operational.initFunc();
      //         this.$refs.vehicle.initFunc();
      //     } else if (type == 'vehicle') {
      //         this.$refs.operational.initFunc();
      //         this.$refs.ride.initFunc();
      //     } else if (type == 'operational') {
      //         this.$refs.ride.initFunc();
      //         this.$refs.vehicle.initFunc();
      //     }

      // }
      this.$emit(
        'changeStatus',
        this.ride_status.data,
        this.vehicle_status.data,
        this.operational_status.data
      )
    },
    changeSearch(text) {
      this.search = text
      if (this.search != '') this.show_search = true
      else this.show_search = false
      var result = []
      this.vehicles.forEach((item) => {
        if (
          item.lock.lock_id
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) != -1 ||
          item.lock.qr_code
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) != -1
        ) {
          result.push(item)
        }
      })

      this.items = result

      // this.$store.dispatch("livemap/saveSearchText", {
      //     data: text
      // });
    },
    onDismiss() {
      this.show_search = false
    },
    SelectedItem(item) {
      this.$emit('changeSearch', item)
    },
  },
}
</script>

<style>
.left-shadow {
  box-shadow: 2px 0px 10px 1px #f1f1f1;
}
.all-vehicle {
  width: 100%;
  height: 60px;
  display: flex;
  border-radius: 8px;
  margin-top: 30px;
  background: #fff500;
}
.all-vehicle-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}
</style>
