<template>
  <div class="w-full mt-4">
    <span class="mb-2 ml-2 font-semibold text-14px text-oBlack">
      {{ title }}
    </span>
    <div class="grid items-center grid-cols-1 mt-3">
      <div class="px-1 py-1 " v-for="(item, index) in status" :key="index">
        <div
          :class="
            item.status
              ? `vehicle-status-item items-center ${item.id}`
              : 'vehicle-status-item items-center'
          "
          @click="onClickedStatus(item, index)"
        >
          <div class="relative flex items-center">
            <img
              :src="require(`@/assets/img/status/${item.icon}`)"
              class="w-8 h-7"
            />
          </div>

          <div class="flex items-center justify-between w-full pl-4">
            <div class="status-sub-text">{{ item.name }}</div>
            <div class="mb-1 status-text">{{ item.count }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import BaseLayout from "@/views/shared/BaseLayout.vue";
//import PageSection from "@/components/layout/PageSection";
import { ReportConfig } from '@/config/ReportConfig'
//import PageSection from '../../components/layout/PageSection.vue';

export default {
  name: 'SettingList',
  props: {
    status: {
      required: false,
    },
    title: {
      required: false,
    },
    type: {
      required: false,
    },
  },
  created() {},
  components: {},
  data() {
    return {
      active_color: '',
    }
  },
  async mounted() {
    window.addEventListener(ReportConfig.events.refresh, () => {
      //this.getIndexData(this.getQueryString);
    })
  },
  methods: {
    // initFunc () {
    //     this.selectedIdx = -1;
    // },
    onClickedStatus(item, index) {
      if (!item.status) {
        // this.selectedArr[idx] = this.setActiveColor(item);
        // console.log('sele = ' , this.selectedArr);
        this.$emit('selectedStatus', item, this.type, index)
      } else {
        // this.selectedArr[idx] = '';
        // console.log('sele = ' , this.selectedArr);
        this.$emit('selectedStatus', null, this.type, index)
      }
    },
    // setActiveColor (data) {
    //     let active_class = '';
    //     if (data.id == 'is_on_ride')
    //         active_class = 'is_on_ride'
    //     else if (data.id == 'is_parking')
    //         active_class = 'is_parking'
    //     else if (data.id == 'is_reserved')
    //         active_class = 'is_reserved'
    //     else if (data.id == 'is_charging')
    //         active_class = 'is_charging'

    //     if (data.id == 'missing')
    //         active_class = 'missing'
    //     else if (data.id == 'geo_fence_alert')
    //         active_class = 'geo_fence_alert'
    //     else if (data.id == 'low_battery')
    //         active_class = 'low_battery'
    //     else if (data.id == 'iot_fault')
    //         active_class = 'iot_fault'

    //     if (data.id == 'rebalance')
    //         active_class = 'rebalance'
    //     else if (data.id == 'maintainance')
    //         active_class = 'maintainance'
    //     else if (data.id == 'charging_pick')
    //         active_class = 'charging_pick'
    //     return active_class;
    // }
  },
}
</script>

<style>
.vehicle-status-item {
  width: 100%;
  /* margin-right: 10px; */
  min-height: 55px;
  border: 1px solid #dddddd;
  /* border: 1px solid #fff; */
  border-radius: 7px;
  display: flex;
  padding: 10px;
  cursor: pointer;
}
/* .vehicle-status-item:focus {
        border: 1px solid #4299e1;
    } */
/* .vehicle-status-item:hover {
        border: 1px solid #4299e1;
    } */
.active_color {
  background: #794edb1c;
  border: 1px solid #4299e1 !important;
}
.status-text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.status-sub-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8e8e8e;
}
.map-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
}
.map-icon i {
  font-size: 13px;
  margin: auto;
  margin-top: 7px;
  color: white;
}

.is_reserved {
  background: #06ae263b;
  border: 1px solid #06ae26 !important;
}
.is_parking {
  background: #068eff2b;
  border: 1px solid #068eff !important;
}
.is_on_ride {
  background: #d55fe424;
  border: 1px solid #d55fe4 !important;
  /* color: #d55fe4; */
}
.is_charging {
  background: #7c4edb30;
  border: 1px solid #7c4edb !important;
}
.missing {
  background: #d0044738;
  border: 1px solid #d00447 !important;
}
.iot_fault {
  background: #ff84003b;
  border: 1px solid #ff8400 !important;
}
.low_battery {
  background: #ff151538;
  border: 1px solid #ff1515 !important;
}
.geo_fence_alert {
  background: #ff151538;
  border: 1px solid #ff1515 !important;
}
.rebalance {
  background: #068eff36;
  border: 1px solid #068eff !important;
}
.maintainance {
  background: #00cf9038;
  border: 1px solid #00cf90 !important;
}
.charging_pick {
  background: #ff151538;
  border: 1px solid #ff1515 !important;
}

@media (max-width: 1500px) {
  .vehicle-status-item {
    width: 100%;
    height: 55px;
    margin: 0px;
    margin-bottom: 10px;
    border: 1px solid #dddddd;
    border-radius: 7px;
    display: flex;
    padding: 0 10px;
    cursor: pointer;
  }
}
</style>
