<template>
  <div
    class="setting-body mr-2 mb-2 mt-2"
    @click="clickSelectBox"
    v-if="type != 'export'"
  >
    <div class="flex h-full items-center pl-3">
      <div v-if="type == 'export'">
        <i class="fas fa-arrow-alt-circle-down"></i>
      </div>
      <div class="grid items-center ml-3">
        <span class="pro-setting-title">{{ selected_title }}</span>
      </div>
      <div class="block custom-right">
        <oto-drow-down-icon></oto-drow-down-icon>
      </div>
    </div>
    <oto-map-drow-down-menu
      v-if="type != 'date'"
      :items="items"
      :show_menu="show_menu"
      v-on:selItem="SelectedItem"
    ></oto-map-drow-down-menu>
  </div>

  <div
    v-else
    class="export-body mr-2 mb-2 mt-2 shadow-md"
    @click="clickSelectBox"
  >
    <div class="flex h-full items-center pl-3">
      <div>
        <i class="fas fa-arrow-alt-circle-down"></i>
      </div>
      <div class="grid items-center ml-3">
        <span class="pro-setting-title">{{ selected_title }}</span>
      </div>
      <div class="block custom-right">
        <oto-drow-down-icon></oto-drow-down-icon>
      </div>
    </div>
    <oto-map-drow-down-menu
      v-if="type != 'date'"
      :items="items"
      :show_menu="show_menu"
      v-on:selItem="SelectedItem"
    ></oto-map-drow-down-menu>
  </div>
</template>

<script>
import { ReportConfig } from '@/config/ReportConfig'
import OtoMapDrowDownMenu from '@/components/ui/OtoMapDrowDownMenu.vue'
import OtoDrowDownIcon from '@/components/ui/OtoDrowDownIcon'

export default {
  name: 'SettingItem',
  components: {
    OtoDrowDownIcon,
    OtoMapDrowDownMenu,
  },
  props: {
    items: {
      required: false,
    },
    title: {
      required: false,
    },
    type: {
      required: false,
    },
  },
  data() {
    return {
      show_menu: false,
      selected_title: this.title,
    }
  },
  async mounted() {
    window.addEventListener(ReportConfig.events.refresh, () => {
      //this.getIndexData(this.getQueryString);
    })
  },
  methods: {
    clickSelectBox() {
      this.show_menu = !this.show_menu
    },
    SelectedItem(item) {
      console.log('tyope = ', this.type)
      if (this.type != 'export') this.selected_title = item.name
      this.$emit('sort', item, this.type)
    },
  },
}
</script>

<style>
.export-body {
  width: 180px;
  height: 40px;
  border: solid 1px #f5f5f5;
  float: right;
  cursor: pointer;
  background: white;
}
.setting-body {
  width: 180px;
  height: 40px;
  border-radius: 100px;
  border: solid 1px #f5f5f5;
  float: right;
  cursor: pointer;
  background: #f3f3f3;
}
.pro-setting-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
@media (max-width: 640px) {
  .setting-body {
    width: 160px !important;
    margin-bottom: 10px;
  }
}
</style>
