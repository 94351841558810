<template>
  <t-button :variant="getVariant()" @click="$emit('click')" :style="getStyle()">
    <svg
      class="w-6 h-5"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
      ></path>
    </svg>
    <template v-if="text">
      <span class="ml-2">{{ text }}</span>
    </template>
  </t-button>
</template>

<script>
export default {
  props: ['text', 'variant'],
  methods: {
    getStyle() {
      let style = ''
      if (this.variant === 'teal') {
        style += 'background-color: #ebf8fa; color: #1693ba; '
      }
      style += this.text ? ' ' : 'padding-left: 6px; padding-right: 6px; '
      return style
    },
    getClass() {
      return this.text ? '' : 'w-36px'
    },
    getVariant() {
      return this.variant
    },
  },
}
</script>

<style></style>
